<template>
	<div class="main_wrp">
		<div class="top_head"></div>
		<img class="topImg" src="../assets/topPic.jpg"  alt="" />
			<div class="glotbl">
				<div class="tbTit">
					就诊人信息
				</div>
				<div class="tbInnr">
					<label class="midlab">姓名</label>
					<input type="text" placeholder="请填写医疗就诊人姓名" v-model="visitname">
				</div>
				<div class="tbInnr">
					<label class="midlab">身份证号码</label>
					<input type="text" placeholder="请填写医疗就诊人身份证号码" v-model="visitcertno">
				</div>
			</div>
			<div class="glotbl">
				<div class="tbTit">
					联系方式
				</div>
				<div class="tbInnr">
					<label for="">手机号码</label>
					<input type="text" placeholder="请填写手机号码" v-model="phone">
				</div>
			</div>
			<div class="glotbl">
				<div class="tbTit">
					请填写上门服务地址
				</div>
				<div class="tbInnr specilWrp">
					<label style="margin-right: 1rem;">区域</label>
					<span>浙江 温州 </span>
                    <select  class="area-change" v-model="areaindx">
                        <option v-for="item in area" :key="item">{{item}}</option>
                    </select>
				</div>
				<div class="tbInnr">
					<label for="">详细地址</label>
					<input type="text" placeholder="请填写居住地或详细地址" v-model="address">
				</div>
			</div>
			<div class="glotbl">
				<div class="tbInnr">
					<label class="longlab">服务类型</label>					
                    <select  class="area-change" v-model="index">
                        <option v-for="item in array" :key="item">{{item}}</option>
                    </select>
				</div>
				<div class="tbInnr">
					<label class="longlab">选择出险日期</label>
                    <input type="date" v-model="date">
				</div>
			</div>
			<div class="Btmwrp">
				<button type="default" class="btn" @click="sendform">下一步</button>
			</div>
	</div>
</template>

<script>
	export default {
		data() {
			const currentDate = this.getDate({
				format: true
			})
			return {
                domin:this.domin,
				other: "",
				index: 0,
				date: currentDate,
				array: ['医保报销办理', '残疾证委托代办', '长期护理险申报', '特殊门诊申报', '医保政策解答咨询', '医疗保险报销咨询', '其他便民服务咨询', '学生保险报销理赔'],
				area:['鹿城区','瓯海区','永嘉瓯北镇','瑞安市','苍南钱库','苍南灵溪','龙港市'],//,'龙湾区','经济技术开发区'
				areaindx:0,
				phone: '',
				mothod: '',
				visitname: '',
				visitcertno: '',
				district: '浙江 温州 鹿城区',
				address: '',
			}
		},
		
		computed: {
			startDate() {
				return this.getDate('start');
			},
			endDate() {
				return this.getDate('end');
			}
		},
		created() {
			this.insuranceid = this.$route.params.id
			console.log("id:",this.$route.params.id)
		},
		methods: {
			sendform() {
				let that = this

				if (this.visitcertno == '' || this.visitname == "") {
                    alert('请填写完整的信息')
					
					return false
				}


				if (this.phone.length != 11) {
					
                    alert('请填写正确的手机号码')

					return false
				}

				if (this.address == '' || this.district == "") {
					
                    alert('请填写正确的地址信息')

					return false
				}

				
				this.wget({
					url: '/Ajax/WordProduct/applybase',
					data: {
						id: 0,
						phone: this.phone,
						visitname: this.visitname,
						visitcertno: this.visitcertno,
						district: this.district,
						address: this.address,
						mothod: this.mothod,
						date: this.date,
						insuranceid: this.insuranceid
					},
					succ: function(data) {
						console.log(data)
						if(that.mothod == 2){
							uni.navigateTo({
								url: '../applyQuest/applyQuest?id=' + data.id + '&visitname=' + that.visitname + '&address=' + that.address +
									'&date=' + that.date + '&phone=' + that.phone
							})
							return
						}
						uni.navigateTo({
							url: '../applyCheck/applyCheck?id=' + data.id + '&visitname=' + that.visitname + '&address=' + that.address +
								'&date=' + that.date + '&phone=' + that.phone
						})
					}
				})
			},

			getDate(type) {
				const date = new Date();
				let year = date.getFullYear();
				let month = date.getMonth() + 1;
				let day = date.getDate();

				if (type === 'start') {
					year = year - 10;
				} else if (type === 'end') {
					year = year + 2;
				}
				month = month > 9 ? month : '0' + month;;
				day = day > 9 ? day : '0' + day;
				return `${year}-${month}-${day}`;
			},
			onConfirm(e) {
				console.log(e)
				this.pickerText = e.labelArr.join("_");
				this.district = e.labelArr.join("_")

			},
			// openAddres() {
			// 	this.cityPickerValueDefault = [10, 2, 0]
			// 	this.$refs.simpleAddress.open();
			// },

			bindDateChange(e) {
				this.date = e.target.value
				console.log(this.date)
			},
			ChangeArea(e) {
				// console.log('picker发送选择改变，携带值为', e.target.value)
				this.index = e.target.value
				this.areaindx = e.target.value
				this.district = "浙江 温州 "+this.area[e.target.value]
				console.log(this.district)
			},
			bindPickerChange(e) {
				// console.log('picker发送选择改变，携带值为', e.target.value)
				this.index = e.target.value
				this.mothod = e.target.value
			},

		}
	}
</script>

<style>
    select {
        border: none;
        outline: none;
        margin-left: 6px
    }
	.uploadImg {
		border: 1px solid #ccc;
		font-size: 13px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #666;
		height: 50px;
		width: 50px;
		margin: 12px 0;
		border-radius: 4px;
	}
	.specilWrp{
		display: flex;
		justify-content: flex-start;
		align-items: center;
        font-size: 13px;
	}
	.longlab {
		width: 151px;
	}

	.midlab {
		width: 97px;
	}

	.img3Wrp {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		width: calc(100% - 60px);

	}

	.img3Wrp image {
		margin: 12px;
		width: 80px;
		height: 80px;
	}


	.mtips {
		font-size: 13px;
		text-align: center;
		color: #FF0000;
	}

	page {
		background-color: #f7f7f7;
	}

	.main_wrp {
		padding-bottom: 60px;
	}

	.navlistBtn {
		font-size: 14px;
		color: #DD524D;
		text-decoration: underline;
	}

	.infos text {
		color: #FF0000;
	}

	.topImg {
		width: 100%;
		margin: 0;
	}


	.glotbl {
		background-color: #fff;
		margin-bottom: 12px;
	}

	.tbTit,
	.tbInnr {
		padding: 0 12px;
		box-sizing: border-box;
	}

	.tbInnr label {
		font-size: 17px;
	}

	.tbTit {
		position: relative;
		padding-left: 17px;
		color: #fff;
		font-size: 14px;
		line-height: 2;
		background-color: #f97a0a;
	}

	.tbTit::before {
		content: "";
		display: block;
		height: 18px;
		width: 3px;
		position: absolute;
		left: 6px;
		top: 5px;
		background-color: #fff;
	}

	.tbInnr {
		display: flex;
		justify-content: flex-start;
		align-items: center;
        padding: 12px;
		border-bottom: 1px solid #e9e9e9;
	}

	.tbInnr label {
		font-weight: bold;
	}

	.tbInnr input {
		border: none;
		outline: none;
		width: 76%;
		font-size: 16px;
		line-height: 2.1;
		padding-left: 1rem;
		box-sizing: border-box;
	}

	picker {
		width: 100%;
		font-size: 15px;
		padding-left: 1rem;

		box-sizing: border-box;
	}



	.uni-input {
		height: 25px;
		line-height: 25px;
	}

	.tbInnr textarea {
		width: 100%;
		margin: 12px 0;
		border: 1px solid #ccc;
		border-radius: 5px;
		padding: 6px;
		height: 83px;
		padding-left: 1rem;
		box-sizing: border-box;
		font-size: 14px;
		line-height: 1.7;
	}

	.radWrp {
		margin-left: 1rem;
	}

	.radWrp,
	.radWrp label {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
	}

	.radWrp label input {
		border: 1px solid #ccc;
		margin-left: 5px;
		border-radius: 4px;
		font-size: 13px;
		padding-left: 5px;
	}

	.radWrp radio-group {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.radWrp radio {
		transform: scale(0.6)
	}

	.radWrp label {
		margin: 6px 0;
		margin-right: 3%;
		font-size: 13px;
	}

	.Btmwrp {
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 89;
		box-sizing: border-box;
		width: 100%;
		padding: 8px;
		display: flex;
		justify-content: flex-end;
		border-top: 1px solid #ccc;
		background-color: #fff;

	}


	.Btmwrp button {
        border: none;
        border:1px solid #ec6f01;
		color: #fff;
		display: inline-block;
		background-color: #F97A0A;
		margin: 0;
		height: 47px;
		line-height: 47px;
		padding: 0 22px;
		border-radius: 22px;
		text-align: center;
		font-size: 15px;
	}
</style>
